export const environment = {

	production: false,
	local: false,

	baseCommitmentUrl_i: 'https://api-qat.mss.ford.com/WholesaleCommitmentService',
	baseCommitmentUrl_e: 'https://api-qat.mss.ford.com/WholesaleCommitmentService',
	baseProductionOfferUrl_i: 'https://production-offer-service-uat.apps.pp01i.edc1.cf.ford.com',
	baseProductionOfferUrl_e: 'https://production-offer-service-uat.apps.pp01e.edc1.cf.ford.com',
	baseWholesaleAllocationUrl_i: 'https://api-qat.mss.ford.com/wholesaleallocationservice',
	baseWholesaleAllocationUrl_e: 'https://api-qat.mss.ford.com/wholesaleallocationservice',
	baseDealerServiceUrl_i: 'https://api-qat.mss.ford.com/dealerdetails',
	baseDealerService_e: 'https://api-qat.mss.ford.com/dealerdetails',

	baseBulkOrderCreationUrl_i: 'https://api.qa01e.gcp.ford.com/bulkordercreation',
	baseBulkOrderCreationUrl_e: 'https://api.qa01e.gcp.ford.com/bulkordercreation',

	adfsBaseUrl: 'https://fd-usr-sso.login.sys.pp01.edc1.cf.ford.com',
	oauthUrl: 'https://fd-usr-grp-sso.login.sys.pp01.edc1.cf.ford.com/oauth/authorize?',
	client_id: '7305335f-8f3c-4fca-bad1-66a1c2536352',

	redirectUrl_i: 'https://wwwqa.gws.ford.com',
	redirectUrl_e: 'https://wwwqa.gws.ford.com',

	saveVolumeCommitUrl: '/web/v1/volume-level-commitment',
	getAllVolumeCommitUrl: '/web/v1/vehicleline-volume-commitment',
	getWholesaleStatusUrl: '/web/v1/wholesaleStatus',
	getDealerDetailsUrl: '/web/v1/dealer-volume-commitment',
	getDealerGroupsUrl: '/web/v1/dealer-groups',
	getProductionOfferUrl: '/web/v1/get-production-offers',
	getDealerDetailUrl: '/web/v1/get-dealer-details',
	getDealerIdlistUrl: '/web/v1/dealer-ids',
	postEmailServiceUrl: '/web/v1/send-message',
	getDistrictAndZoneListUrl: '/web/v1/market-districts-zones',
	getMessageUrl: '/web/v1/message-broadcast',
	getVolumeCommitDataForAudit: '/web/v1/volume-commitment-by-market',

	openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
	openidClientId: 'urn:globalwholesalewebui:clientid:web_gwsdevwebsite:qa',
	openidResource: 'urn:globalwholesalewebui:resource:web_gwsdevwebsite:qa'

};
